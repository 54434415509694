import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { CompetenceType } from '@gtn/app-common/api/model/CompetenceType';
import { ExampleAndItemResponse, ItemStatus } from '@gtn/app-common/api/model/ExampleAndItemResponse';
import { SubmitItemRequest } from '@gtn/app-common/api/model/SubmitItemRequest';
import AnnotateImageDialog from '@gtn/app-common/components/annotate-image/AnnotateImageDialog';
import { AnnotatePdfDialog } from '@gtn/app-common/components/annotate-pdf-dialog/annotate-pdf-dialog.component';
import { ITEM_STATUS_DISPLAY_VALUES_STUDENT } from '@gtn/app-common/components/example-item-list/ItemStatusDisplay';
import { GradingInput } from '@gtn/app-common/components/grading/GradingInput';
import ItemComments from '@gtn/app-common/components/item-comments/ItemComments';
import styles from '@gtn/app-common/components/submit-item/SubmitItemDialog.module.scss';
import { useSelectedCourse } from '@gtn/app-common/store/app.store.hooks';
import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { ChooseCollaborators } from '@gtn/common/components/choose-collaborators/ChooseCollaborators';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnFileManager, { GtnFile } from '@gtn/common/components/forms/gtn-file-manager/GtnFileManager';
import { GTN_SELECT_DEFAULT_OPTION } from '@gtn/common/components/forms/gtn-select/GtnSelect';
import GtnCheckbox from '@gtn/common/components/forms/GtnCheckbox';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import GtnTextField from '@gtn/common/components/forms/GtnTextField';
import { GtnAvatar } from '@gtn/common/components/gtn-avatar/GtnAvatar';
import IframeDialog from '@gtn/common/components/IframeDialog';
import LinkPreview from '@gtn/common/components/link-preview/LinkPreview';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainer';
import { LoadingIndicatorInline } from '@gtn/common/components/LoadingIndicator';
import GtnDialog, { DialogProps, useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation, useDebounce } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { Utils } from '@gtn/common/utils/Utils';
import { DakoraAPI } from '@gtn/dakora/api/DakoraAPI';
import { IconButton } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { FormikHelpers, FormikProps, FormikValues, useFormikContext } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import * as Yup from 'yup';

export interface ExampleItemDialogProps {
  exampleAndItem?: ExampleAndItemResponse;
  exampleId?: number;
  studentId?: number; // only used when user is teacher

  onSave?: () => void;
}

interface ExampleItemFormValues {
  title?: string;
  text?: string;
  collaborators?: number[];
  studentValue?: string;
  descriptorGradings?: { studentvalue: string; teachervalue: string }[];
  isSubmit?: boolean;
  completed?: boolean;
}

function ExampleItemDialog(props: ExampleItemDialogProps & DialogProps) {
  const appCommonAPI = InjectionContainer.resolve(AppCommonAPI);
  const moodleWebservice = InjectionContainer.resolve(MoodleWebservice);

  const t = useAppTranslation();
  const selectedCourse = useSelectedCourse();

  const isTeacher = useIsTeacher();
  const formContextRef = useRef<FormikProps<any>>();
  const annotateImageDialog = useGtnDialog(AnnotateImageDialog);
  const annotatePdfDialog = useGtnDialog(AnnotatePdfDialog);
  const iframeDialog = useGtnDialog(IframeDialog);
  const commentsRef = React.useRef<React.ElementRef<typeof ItemComments>>(null);

  const [exampleAndItem, setExampleAndItem] = useState(props.exampleAndItem!);
  const [initialFormValues, setInitialFormValues] = React.useState<ExampleItemFormValues>();
  const [files, setFiles] = React.useState<GtnFile[]>([]);
  const [removeFiles, setRemoveFiles] = React.useState<GtnFile[]>([]);
  const [submitFailed, setSubmitFailed] = React.useState(false);
  const [text, setText] = useState('');

  const hasExample = !!exampleAndItem?.example;
  const canStudentEdit = !isTeacher && (exampleAndItem?.status === ItemStatus.New || exampleAndItem?.status === ItemStatus.Inprogress);
  const isH5pExample = !!exampleAndItem?.example?.externaltask_embedded;
  const courseId = exampleAndItem?.courseid;
  const userid = isTeacher ? props.studentId ?? props.exampleAndItem?.item?.owner?.userid : 0;

  const { data: descriptors, progressState: descriptorsProgressState } = useAPI(appCommonAPI.getDescriptorsForExampleItem, [
    exampleAndItem?.courseid,
    userid,
    exampleAndItem?.example?.id,
    exampleAndItem?.topicid,
  ]);

  const debouncedText = useDebounce(text, 1000);
  const textLinks = useMemo(() => Utils.extractUrls(debouncedText), [debouncedText]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape<ExampleItemFormValues>({
        text: Yup.string().label(t('submit-item.text')),
      }),
    []
  );

  useAsyncEffect(async () => {
    await loadExampleAndItem();
  }, [props.exampleAndItem, props.exampleId]);

  useEffect(() => {
    if (exampleAndItem) {
      const descriptorGradings = descriptors?.map((descriptor) => ({
        studentvalue: String(descriptor.studentevaluation ?? GTN_SELECT_DEFAULT_OPTION),
        teachervalue: String(descriptor.teacherevaluation ?? GTN_SELECT_DEFAULT_OPTION),
      }));

      const studentValue =
        // alte Variante: beim item speichern
        exampleAndItem.item?.studentvalue !== undefined
          ? String(exampleAndItem.item.studentvalue)
          : // neue Variante: beim example speichern
          exampleAndItem.example?.student_evaluation !== undefined
          ? String(exampleAndItem.example.student_evaluation)
          : '';

      if (exampleAndItem.item) {
        setInitialFormValues({
          title: exampleAndItem.item.name || exampleAndItem.example?.title,
          text: exampleAndItem.item.solutiondescription || exampleAndItem.item.effort || '', // strangely this can be in both fields?
          collaborators: exampleAndItem.item.collaborators?.map((user) => Number(user.userid)),
          studentValue,
          descriptorGradings: descriptorGradings,
          completed: exampleAndItem.status === ItemStatus.Completed,
        });

        if (exampleAndItem.item?.studentfiles) {
          setFiles(
            exampleAndItem.item?.studentfiles.map((file) => ({
              id: file.id,
              name: file.filename,
              url: file.file,
              type: file.mimetype,
            }))
          );
        }
      } else {
        setInitialFormValues({
          descriptorGradings: descriptorGradings,
          studentValue,
        });
      }
    }
  }, [exampleAndItem, descriptors]);

  const onSave = async (values: ExampleItemFormValues, formHelper: FormikHelpers<ExampleItemFormValues>) => {
    setSubmitFailed(false);

    try {
      const filesToUpload = files.filter((file) => file instanceof File);
      const moodleFiles = await Promise.all(filesToUpload.map((file) => moodleWebservice.uploadFile(file as File)));

      let submitResult;
      if (!isTeacher) {
        const descriptorGradings = values.descriptorGradings
          ? descriptors?.map((descriptor, index) => {
              const descriptorGrading = values.descriptorGradings?.[index].studentvalue;
              return {
                descriptorid: Number(descriptor.descriptorid),
                studentvalue: descriptorGrading !== GTN_SELECT_DEFAULT_OPTION ? Number(descriptorGrading) : -1,
              };
            })
          : [];

        const submitItemRequest: SubmitItemRequest = {
          courseid: courseId,
          url: null,
          itemid: exampleAndItem.item?.id || 0,

          // das item wird, wenn vorhanden, dem example zugeteilt, sonst dem ausgewählten topic
          compid: exampleAndItem.example?.id ? exampleAndItem.example?.id : exampleAndItem.topicid,
          comptype: exampleAndItem.example?.id ? CompetenceType.Example : CompetenceType.Topic,

          itemtitle: values.title || exampleAndItem.example?.title || '',
          solutiondescription: values.text || '',
          studentcomment: '', // TODO remove
          collabuserids: values.collaborators,

          filenames: moodleFiles.map((file) => file.filename),
          fileitemids: moodleFiles.map((file) => file.itemid),
          removefiles: removeFiles.map((file) => file.id) as number[],

          studentvalue: values.studentValue != null && values.studentValue !== GTN_SELECT_DEFAULT_OPTION ? Number(values.studentValue) : -1,
          descriptorgradings: descriptorGradings,

          submit: values.isSubmit || false,
        };

        submitResult = await appCommonAPI.submitItem(submitItemRequest);
      } else {
        const descriptorGradings = values.descriptorGradings
          ? descriptors?.map((descriptor, index) => {
              const descriptorGrading = values.descriptorGradings?.[index].teachervalue;
              return {
                descriptorid: Number(descriptor.descriptorid),
                teachervalue: descriptorGrading !== GTN_SELECT_DEFAULT_OPTION ? Number(descriptorGrading) : -1,
              };
            })
          : [];

        submitResult = await appCommonAPI.gradeItem({
          itemid: exampleAndItem.item!.id,
          teachervalue: values.completed ? 1 : -1,
          descriptorgradings: descriptorGradings,
        });
      }

      formHelper.setSubmitting(false);

      if (submitResult.success) {
        props.onSave?.();

        if (values.isSubmit) {
          props.onClose?.();
        } else {
          loadExampleAndItem();
        }

        if (exampleAndItem.item?.id == null) {
          exampleAndItem.item = { id: submitResult.itemid, name: '' };
          exampleAndItem.status = values.isSubmit ? ItemStatus.Submitted : ItemStatus.Inprogress;
        }

        return submitResult.itemid;
      } else {
        setSubmitFailed(true);
      }
    } catch (exception) {
      GtnLogger.warn(exception);
      setSubmitFailed(true);
    }
    return undefined;
  };

  async function loadExampleAndItem() {
    const exampleId = props.exampleId ?? props.exampleAndItem?.example?.id;
    if (exampleId) {
      if (selectedCourse) {
        try {
          let response: ExampleAndItemResponse;

          if (isTeacher && props.studentId) {
            response = await appCommonAPI.teacherGetStudentExampleAndItem(props.studentId, exampleId, selectedCourse.id);
          } else {
            response = await appCommonAPI.studentGetExampleAndItem(exampleId, selectedCourse.id);
          }

          setExampleAndItem(response);
        } catch (e) {
          GtnLogger.warn(e);
        }
      }
    }
  }

  function openAnnotateImageDialog(imageUrl: string, saveImageFileName: string) {
    annotateImageDialog.open({
      backgroundImageUrl: imageUrl,
      saveImageFileName,
    });
  }

  function addFiles(addedFiles: GtnFile[]) {
    const oldFiles = files.filter((file) => addedFiles.some((addedFile) => addedFile.name === file.name));
    const newFiles = files.filter((file) => !oldFiles.some((oldFile) => oldFile === file));
    setFiles([...newFiles, ...addedFiles]);

    setRemoveFiles([...removeFiles, ...oldFiles.filter((oldFile) => oldFile?.id)]);
    formContextRef.current?.submitForm();
  }

  const attachAnnotatedPDF = async (content: ArrayBuffer, originalFileName?: string) => {
    try {
      const blob = new Blob([content], { type: 'application/pdf' });
      const file = new File([blob], originalFileName || 'Anmerkungen.pdf', { type: 'application/pdf' });

      if (!isTeacher && [ItemStatus.New, ItemStatus.Inprogress].includes(exampleAndItem.status as ItemStatus)) {
        addFiles([file]);
      } else {
        const fileUploadResponse = await moodleWebservice.uploadFile(file);
        const response = await appCommonAPI.submitComment(props.exampleAndItem!.item!.id, '', fileUploadResponse.itemid);
        commentsRef.current?.reloadComments();
      }
      annotatePdfDialog.close();
    } catch (e) {
      GtnLogger.warn(e);
    }
  };

  function renderAttachedImage(url: string, name: string, isExternalFile: boolean) {
    if (!name) {
      name = 'bild.png';
    }

    return (
      <div className={styles.headerImage} style={{ ['--aspect-ratio' as any]: 16 / 6 }}>
        <img src={url} />
        <IconButton
          className={styles.editHeaderImageButton}
          onClick={(e) => {
            if (!isExternalFile) {
              annotatePdfDialog.open({ fileUrl: url + '&as_pdf=1&add_blank_page=0', fileName: name + '.pdf' });
            } else {
              openAnnotateImageDialog(appCommonAPI.getRequestExternalFileUrl(url), name);
            }
            e.preventDefault();
          }}
        >
          <Create />
        </IconButton>
      </div>
    );
  }

  async function loadH5pResults() {
    if (exampleAndItem.example?.id && canStudentEdit) {
      try {
        const result = await appCommonAPI.getLastH5pActivityResult(exampleAndItem.example.id);
        if (result) {
          setInitialFormValues({ text: t('submit-item.h5p-result-text', { points: result.current_result.raw_score, maxPoints: result.current_result.max_score, link: result.resultpage_url }) });
        }
      } catch (e) {}
    }
    iframeDialog.close();
  }

  function renderExample() {
    return (
      <>
        {exampleAndItem.example?.taskfiles?.filter((taskFile) => taskFile.type.match(/^image\//)).map((taskFile) => renderAttachedImage(taskFile.url, taskFile.name, false))}

        {exampleAndItem.example?.externaltask && Utils.isImagePath(exampleAndItem.example?.externaltask) && renderAttachedImage(exampleAndItem.example?.externaltask, '', true)}

        {(exampleAndItem.subjecttitle || exampleAndItem.topictitle) && (
          <p className={styles.competence}>
            {exampleAndItem.subjecttitle} / {exampleAndItem.topictitle}
          </p>
        )}

        {exampleAndItem.niveautitle && <p className={styles.competence}>{exampleAndItem.niveautitle}</p>}

        {hasExample ? (
          <h2 style={{ fontWeight: 'bold' }}>{exampleAndItem.example?.title ?? exampleAndItem.item?.name}</h2>
        ) : (
          <GtnTextField name="title" label={t('submit-item.title')} disabled={!canStudentEdit} />
        )}

        {hasExample && (
          <>
            {exampleAndItem.example?.annotation && (
              <>
                <div style={{ whiteSpace: 'pre-line', marginTop: 8 }}>{exampleAndItem.example?.annotation}</div>
              </>
            )}

            <p style={{ marginTop: 8 }}>
              <p>{exampleAndItem.example?.description}</p>
              <p className={styles.taskFileLink}>
                {exampleAndItem.example?.externalurl && (
                  <div>
                    •{' '}
                    <a href={Utils.ensureValidUrl(exampleAndItem.example?.externalurl)} target="_blank" rel="noopener noreferrer">
                      {exampleAndItem.example?.externalurl}
                    </a>
                  </div>
                )}
                {exampleAndItem.example?.externaltask && !isH5pExample && (
                  <div>
                    •{' '}
                    <a href={Utils.ensureValidUrl(exampleAndItem.example?.externaltask)} target="_blank" rel="noopener noreferrer">
                      {exampleAndItem.example?.externaltask}
                    </a>
                  </div>
                )}

                {isH5pExample && (
                  <div>
                    •{' '}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        iframeDialog.open({ title: exampleAndItem.example?.title, src: exampleAndItem.example?.externaltask_embedded, disableCloseByClickingOutside: true });
                      }}
                    >
                      {t('learning-plans.start-example')}
                    </a>
                  </div>
                )}
              </p>
            </p>

            {exampleAndItem.example?.taskfiles
              ?.filter((taskFile) => !taskFile.type.includes('image'))
              .map((taskFile) => (
                <p className={styles.taskFileLink} key={taskFile.name}>
                  • <b>{taskFile.name}</b> (
                  <a href={taskFile.url} target="_blank" rel="noopener noreferrer">
                    {t('submit-item.open-file')}
                  </a>
                  {taskFile.type == 'application/pdf' && (
                    <>
                      {', '}
                      <a href="#" onClick={() => annotatePdfDialog.open({ fileUrl: taskFile.url + '&as_pdf=1&add_blank_page=0', fileName: taskFile.name })}>
                        {t('submit-item.annotate-pdf')}
                      </a>
                    </>
                  )}
                  )
                </p>
              ))}
          </>
        )}
      </>
    );
  }

  function renderDescriptorGradings(saveForm: () => void) {
    return (
      <>
        <h3 className={styles.sectionHeader}>{t('evaluation.self-evaluation.title-overall')}</h3>
        <div className={styles.competenceGradingTable} style={{ rowGap: 0, paddingBottom: 0 }}>
          <h4 style={{ textAlign: 'left', paddingLeft: 16 }}>{t('submit-item.header-dialog')}</h4>
          <h4>{t('role.student')}</h4>
          <h4>{t('role.teacher')}</h4>
          <p style={{ paddingLeft: 16 }}>{t('evaluation.self-evaluation.description-overall')}</p>

          <GradingInput
            name="studentValue"
            readonly={isTeacher}
            gradingType="student"
            noGradingDisplay={'-'}
            disabled={!canStudentEdit}
            grading={exampleAndItem.item?.studentvalue}
            label={t('evaluation.self-evaluation.placeholder')}
            className={styles.gradingSelector}
            onChange={() => saveForm()}
          />
          <GtnCheckbox disabled={!isTeacher || exampleAndItem.status !== ItemStatus.Submitted} label={t('review-item.mark-as-completed')} name="completed" />
        </div>

        <h3 className={styles.sectionHeader}>{t(!isTeacher ? 'evaluation.self-evaluation.title-competences' : 'review-item.header-teacher-competences-grading')}</h3>
        <LoadingContainer state={descriptorsProgressState} className={styles.competenceGradingTable} emptyText={t('evaluation.self-evaluation.no-competences')}>
          <h4 style={{ textAlign: 'left', paddingLeft: 16 }}>{t('competence')}</h4>
          <h4>{t('role.student')}</h4>
          <h4>{t('role.teacher')}</h4>

          {descriptors?.map((descriptor, index) => (
            <>
              <p style={{ paddingLeft: 16 }}>{descriptor.title}</p>

              <GradingInput
                readonly={isTeacher}
                gradingType="student"
                name={`descriptorGradings.${index}.studentvalue`}
                grading={descriptor.studentevaluation}
                noGradingDisplay={'-'}
                className={styles.gradingSelector}
                onChange={() => saveForm()}
              />

              <GradingInput
                readonly={!isTeacher}
                gradingType="teacher"
                grading={descriptor.teacherevaluation}
                name={`descriptorGradings.${index}.teachervalue`}
                className={styles.gradingSelector}
                noGradingDisplay={'-'}
                disabled={![ItemStatus.Submitted, ItemStatus.Completed].includes(exampleAndItem.status as ItemStatus)}
                onChange={() => saveForm()}
              />
              <hr />
            </>
          ))}
        </LoadingContainer>
      </>
    );
  }

  return (
    <>
      <GtnDialog {...props} maxWidth="xl" title={t('submit-item.header-dialog')} disableCloseByClickingOutside={true}>
        {!exampleAndItem ? (
          <LoadingIndicatorInline />
        ) : (
          <GtnForm formContextRef={formContextRef} initialValues={initialFormValues} className={styles.dialogContentContainer} onSubmit={onSave} validationSchema={validationSchema}>
            {(formHelper) => {
              setText(formHelper.values.text || '');

              const saveForm = async () => {
                await formHelper.submitForm();
              };

              const itemStatusDisplay = ITEM_STATUS_DISPLAY_VALUES_STUDENT.find((v) => v.statusMapping === exampleAndItem.status);

              return (
                <>
                  <div className={styles.contentContainer}>
                    <div className={styles.scrollContainer}>
                      {renderExample()}

                      <h3 className={styles.sectionHeader}>{t(!isTeacher ? 'submit-item.header-solution' : 'review-item.header-solution')}</h3>
                      {!isH5pExample && (
                        <GtnFileManager
                          className={styles.formElement}
                          files={files}
                          disabled={!canStudentEdit}
                          onFilesAdded={(files) => {
                            if (files.length) {
                              addFiles(files);
                            }
                          }}
                          onFileRemoved={(removedFile) => {
                            if (removedFile.id) {
                              setRemoveFiles([...removeFiles, removedFile]);
                            }
                            setFiles(files.filter((file) => file !== removedFile));
                            saveForm();
                          }}
                          onEditFile={
                            hasExample
                              ? (editFile) => {
                                  if (editFile.url) {
                                    const fileUrl = editFile.url; // || URL.createObjectURL(editFile as File);
                                    if (editFile.type.includes('image')) {
                                      openAnnotateImageDialog(fileUrl, editFile.name);
                                    } else {
                                      annotatePdfDialog.open({ fileUrl, fileName: editFile.name });
                                    }
                                  }
                                }
                              : undefined
                          }
                          openFile={(file) => {
                            if (isTeacher) {
                              if (file.type.includes('pdf') || file.type.match(/image\//)) {
                                annotatePdfDialog.open({ fileUrl: file.url + '&as_pdf=1&add_blank_page=0', fileName: file.name });
                                return true;
                              }
                            }
                            return false;
                          }}
                        />
                      )}

                      <GtnTextField className={styles.formElement} onBlur={() => saveForm()} disabled={!canStudentEdit || isH5pExample} name="text" label={t('submit-item.text')} multiline rows={4} />

                      {textLinks.length > 0 && (
                        <div className={styles.linkPreviewContainer}>
                          {textLinks.map((url) => (
                            <LinkPreview url={url} key={url} />
                          ))}
                        </div>
                      )}

                      <ChooseCollaborators className={styles.formElement} onChange={() => saveForm()} disabled={!canStudentEdit} courseId={courseId} name="collaborators" />

                      {renderDescriptorGradings(() => saveForm())}
                    </div>

                    <div className={styles.scrollContainer} style={{ minWidth: '30%' }}>
                      {exampleAndItem?.item?.owner && (
                        <div style={{ display: 'flex', gap: 8, alignItems: 'center', marginBottom: 16 }}>
                          <GtnAvatar imageUrl={exampleAndItem.item.owner.profileimageurl} name={exampleAndItem.item.owner.fullname} />
                          <p style={{ fontWeight: 'bold' }}>{exampleAndItem.item.owner.fullname}</p>
                        </div>
                      )}

                      <h3 className={styles.itemCommentsHeader}>{t('item-comments.title')}</h3>
                      <ItemComments
                        itemId={exampleAndItem.item?.id}
                        ref={commentsRef}
                        beforeSave={async () => {
                          if (!exampleAndItem.item?.id) {
                            formHelper.setSubmitting(true);
                            const itemId = await onSave(formHelper.values, formHelper);
                            formHelper.setSubmitting(false);
                            return itemId ?? false;
                          }
                          return true;
                        }}
                        onOpenFile={(file) => {
                          if (file.mimetype.includes('pdf')) {
                            annotatePdfDialog.open({ fileUrl: file.file, fileName: file.filename });
                          } else {
                            window.open(file.file, '_blank');
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.submitContainer}>
                    {itemStatusDisplay && (
                      <p className={styles.itemStatus} style={{ backgroundColor: itemStatusDisplay.color }}>
                        {t(itemStatusDisplay.textResId)}
                      </p>
                    )}
                    <p>{submitFailed ? t('submit-item.error') : ''}</p>

                    {!isTeacher && (
                      <>
                        {exampleAndItem.status === ItemStatus.New && (
                          <GtnButton
                            type="button"
                            onClick={async () => await saveForm()}
                            actionType="secondary"
                            label={t('submit-item.start-example')}
                            disabled={!canStudentEdit || formHelper.isSubmitting || !(formHelper.dirty || initialFormValues != undefined) || !formHelper.isValid}
                            loading={!formHelper.values.isSubmit && formHelper.isSubmitting}
                          />
                        )}

                        <GtnButton
                          type="button"
                          onClick={async () => {
                            await formHelper.setValues({ ...formHelper.values, isSubmit: true }, true);
                            await saveForm();
                          }}
                          actionType="primary"
                          label={t('submit-item.complete')}
                          disabled={
                            !canStudentEdit || formHelper.isSubmitting || !(formHelper.dirty || initialFormValues != undefined) || !formHelper.isValid || (!formHelper.values.text && !files?.length)
                          }
                          loading={formHelper.isSubmitting}
                        />
                      </>
                    )}

                    {isTeacher && (
                      <>
                        {[ItemStatus.Submitted].includes(exampleAndItem.status as ItemStatus) && (
                          <GtnButton
                            type="button"
                            onClick={async () => {
                              if (isTeacher && exampleAndItem.item) {
                                await appCommonAPI.setItemStatus(exampleAndItem.item.id, ItemStatus.Inprogress);
                                props.onSave?.();
                                props.onClose?.();
                              }
                            }}
                            actionType="secondary"
                            label={t('review-item.set-status-needs-work')}
                            disabled={formHelper.isSubmitting}
                          />
                        )}
                        <GtnButton
                          type="button"
                          onClick={async () => {
                            await formHelper.setValues({ ...formHelper.values, isSubmit: true }, true);
                            await commentsRef.current?.submitComment();
                            await saveForm();
                          }}
                          actionType="primary"
                          label={t('save')}
                          disabled={formHelper.isSubmitting || !formHelper.dirty}
                          loading={formHelper.isSubmitting}
                        />
                      </>
                    )}
                  </div>
                </>
              );
            }}
          </GtnForm>
        )}
      </GtnDialog>

      <annotateImageDialog.Component
        onSavedAsImage={(imageFile) => {
          addFiles([imageFile]);
          annotateImageDialog.close();
        }}
      />
      <annotatePdfDialog.Component onSave={attachAnnotatedPDF} />
      <iframeDialog.Component onClose={() => loadH5pResults()} />
    </>
  );
}

export default ExampleItemDialog;
