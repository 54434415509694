import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { CompetenceType } from '@gtn/app-common/api/model/CompetenceType';
import { ExacompNiveau } from '@gtn/app-common/api/model/ExacompNiveau';
import { ExampleAndItemResponse, ItemStatus } from '@gtn/app-common/api/model/ExampleAndItemResponse';
import CourseStudentSelectorSidebar from '@gtn/app-common/components/app-navigation/course-student-selection/sidebar/CourseStudentSelectorSidebar';
import { ExampleItemList } from '@gtn/app-common/components/example-item-list/ExampleItemList';
import { InviteStudentsDialog } from '@gtn/app-common/components/invite-students-dialog/InviteStudentsDialog.component';
import ExampleItemDialog from '@gtn/app-common/components/submit-item/ExampleItemDialog';
import ChooseCompetenceFilterDialog from '@gtn/app-common/components/workbook/choose-competences/ChooseCompetenceFilterDialog';
import styles from '@gtn/app-common/components/workbook/Workbook.module.scss';
import { useSelectedCourse, useSelectedStudent } from '@gtn/app-common/store/app.store.hooks';
import { ServerInfoManager } from '@gtn/app-common/utils/ServerInfoManager';
import { ExacompSubject } from '@gtn/common/api/model/exacomp/ExacompSubject';
import { ExacompTopic } from '@gtn/common/api/model/exacomp/ExacompTopic';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import FilterBar, { Filter } from '@gtn/common/components/filter-bar/FilterBar';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { GtnChooserOption } from '@gtn/common/components/gtn-chooser/GtnChooser';
import { GtnSwitchButtons } from '@gtn/common/components/gtn-switch-buttons/GtnSwitchButtons.component';
import { LoadingIndicatorInline, LoadingIndicatorOverlay } from '@gtn/common/components/LoadingIndicator';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import GtnSearchBar from '@gtn/common/components/search-bar/GtnSearchBar';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation, useDebounce } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { ProgressState } from '@gtn/common/utils/ProgressState';
import { Fab, FormControl, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Add, AddCircle, CancelRounded, ViewList, ViewModule } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ITEM_STATUS_DISPLAY_VALUES_STUDENT } from '../example-item-list/ItemStatusDisplay';

export enum TypeFilter {
  Examples = 'examples',
  OwnItems = 'own_items',
}

export enum ListStyle {
  List = 'list',
  Grid = 'grid',
}

export interface WorkbookProps {
  showTypeFilter?: boolean;
}

export default function Workbook(props: WorkbookProps) {
  const serverInfo = InjectionContainer.resolve(ServerInfoManager).getServerInfo();

  const t = useAppTranslation();
  const isTeacher = useIsTeacher();
  const selectedStudent = useSelectedStudent(); // only used when user is teacher
  const selectedCourse = useSelectedCourse();
  const location = useLocation<any>();

  const statusFilters: Filter<ItemStatus | null>[] = [
    { value: null, displayName: t('workbook.status-filter.all') },
    ...ITEM_STATUS_DISPLAY_VALUES_STUDENT.map((status) => ({
      value: status.statusMapping,
      displayName: t(status.textResId),
      icon: status.color,
    })),
  ];
  const typeFilters: Filter<TypeFilter | null>[] = [
    { value: null, displayName: t('workbook.type-filter.all') },
    {
      value: TypeFilter.Examples,
      displayName: t('workbook.type-filter.examples'),
    },
    {
      value: TypeFilter.OwnItems,
      displayName: t.withRole('workbook.type-filter.own-items'),
    },
  ];

  const listStyleOptions: GtnChooserOption[] = [
    {
      title: <ViewList />,
      id: ListStyle.List,
    },
    {
      title: <ViewModule />,
      id: ListStyle.Grid,
    },
  ];

  const chooseCompetenceFilterDialog = useGtnDialog(ChooseCompetenceFilterDialog);
  const exampleItemDialog = useGtnDialog(ExampleItemDialog);
  const inviteStudentsDialog = useGtnDialog(InviteStudentsDialog);
  const chooseCompetenceForAddItemDialog = useGtnDialog(ChooseCompetenceFilterDialog);

  const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);
  const [selectedListStyle, setSelectedListStyle] = useState<GtnChooserOption>(listStyleOptions[0]);
  const [selectedTypeFilter, setSelectedTypeFilter] = useState<TypeFilter | null>(null);
  const [selectedSubjectFilter, setSelectedSubjectFilter] = useState<ExacompSubject | null>(null);
  const [selectedTopicFilter, setSelectedTopicFilter] = useState<ExacompTopic | null>(null);
  const [selectedNiveauFilter, setSelectedNiveauFilter] = useState<ExacompNiveau | null>(null);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<ItemStatus | null>(null);
  const [preSelectedItemId, setPreSelectedItemId] = useState<number | null>(null);

  // set when clicked on a notification
  const preSelectedTypeFilter = location.state?.typeFilter;
  const preSelectedStatusFilter = location.state?.statusFilter;

  useEffect(() => {
    if (preSelectedTypeFilter !== undefined) {
      setSelectedTypeFilter(preSelectedTypeFilter);
    }

    if (preSelectedStatusFilter !== undefined) {
      setSelectedStatusFilter(preSelectedStatusFilter);
    }
  }, [preSelectedTypeFilter, preSelectedStatusFilter, location.state?.seq]);

  const appCommonAPI = InjectionContainer.resolve(AppCommonAPI);
  const debouncedSearch = useDebounce(searchFilter, 1000);

  let comptype = 0;
  let compid = 0;
  if (selectedTopicFilter) {
    comptype = CompetenceType.Topic;
    compid = selectedTopicFilter.id;
  } else if (selectedSubjectFilter) {
    comptype = CompetenceType.Subject;
    compid = selectedSubjectFilter.id;
  }

  const {
    data: items,
    progressState,
    mutate: reloadItems,
    isValidating,
  } = useAPI(
    isTeacher ? appCommonAPI.getTeacherExamplesAndItems : appCommonAPI.getStudentExamplesAndItems,
    isTeacher
      ? !selectedCourse?.id
        ? null
        : [selectedTypeFilter ?? '', comptype, compid, selectedNiveauFilter?.niveauid, debouncedSearch, selectedStatusFilter, selectedCourse?.id, selectedStudent?.id]
      : [selectedTypeFilter ?? '', comptype, compid, selectedNiveauFilter?.niveauid, debouncedSearch, selectedStatusFilter, selectedCourse?.id]
  );

  useEffect(() => {
    setPreSelectedItemId(location.state?.itemId);
  }, [location.state?.itemId, location.state?.seq]);

  useEffect(() => {
    if (items?.length && preSelectedItemId) {
      const exampleAndItem = items?.find((i) => i.item?.id === preSelectedItemId);

      if (exampleAndItem) {
        exampleItemDialog.open({ exampleAndItem });
        setPreSelectedItemId(null);
      }
    }
  }, [items, preSelectedItemId]);

  const { data: niveaus } = useAPI(appCommonAPI.getNiveausForSubject, selectedSubjectFilter ? [selectedSubjectFilter.id] : null);

  const onStatusFilterChanged = (filter: Filter<ItemStatus | null>) => {
    setSelectedStatusFilter(filter.value);
  };

  const onTypeFilterChanged = (filter: Filter<TypeFilter | null>) => {
    setSelectedTypeFilter(filter.value);
  };

  const clearSelectedCompetenceFilter = () => {
    setSelectedSubjectFilter(null);
    setSelectedTopicFilter(null);
    setSelectedNiveauFilter(null);
  };

  const onNewEntryClicked = () => {
    chooseCompetenceForAddItemDialog.open();
  };

  const renderFilters = (
    <>
      <div className={styles.filterContainer}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={styles.competenceAreaFilterContainer} onClick={() => chooseCompetenceFilterDialog.open()}>
            <p>{t('competence-area')}:&nbsp;</p>

            {!selectedSubjectFilter && !selectedTopicFilter && <AddCircle />}
            <p className={styles.selectedCompetence}>
              {selectedSubjectFilter?.title}
              {selectedTopicFilter && <span style={{ display: 'block' }}>{selectedTopicFilter.title}</span>}
            </p>

            {selectedSubjectFilter && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  clearSelectedCompetenceFilter();
                }}
              >
                <CancelRounded />
              </IconButton>
            )}
          </div>{' '}
          <div style={{ flex: 1 }}></div>
          <GtnSwitchButtons selected={selectedListStyle} onSelected={(option) => setSelectedListStyle(option)} options={listStyleOptions} />
        </div>

        {selectedSubjectFilter && niveaus && (
          <FormControl variant="outlined">
            <InputLabel>{t('workbook.niveau-filter.title')}</InputLabel>
            <Select
              value={selectedNiveauFilter?.niveauid}
              label={t('workbook.niveau-filter.title')}
              onChange={(e) => setSelectedNiveauFilter(niveaus?.find((n) => n.niveauid === e.target.value) ?? null)}
            >
              <MenuItem value={-1}>{t('workbook.niveau-filter.all')}</MenuItem>
              {niveaus?.map((niveau) => (
                <MenuItem key={niveau.niveauid} value={niveau.niveauid}>
                  {niveau.niveautitle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <div className={styles.mainFilterContainer}>
          <FilterBar filters={statusFilters} selectedFilter={selectedStatusFilter} onFilterSelected={onStatusFilterChanged} title={t('workbook.status-filter.title')} />
          <FilterBar
            filters={typeFilters}
            selectedFilter={selectedTypeFilter}
            onFilterSelected={onTypeFilterChanged}
            style={{ borderLeft: '1px solid lightgray', marginLeft: 8, paddingLeft: 8 }}
            title={t('workbook.type-filter.title')}
          />
          <div style={{ flex: 1 }}></div>
          <GtnSearchBar className={styles.searchBar} label={t('workbook.search')} value={searchFilter} onValueChanged={setSearchFilter} />
        </div>
      </div>
    </>
  );

  function renderContent() {
    const validatingStyle = isValidating ? { opacity: '30%' } : {};

    switch (progressState) {
      case ProgressState.Loading:
        return <LoadingIndicatorInline />;
      case ProgressState.Content:
        return (
          <>
            <div className={styles.listContainer} style={validatingStyle}>
              <ExampleItemList items={items} reloadItems={reloadItems} statusFilter={selectedStatusFilter} typeFilter={selectedTypeFilter} listStyle={selectedListStyle.id as ListStyle} />
            </div>
          </>
        );
      case ProgressState.Error:
        return (
          <Alert severity="error" style={validatingStyle}>
            {t('list-error-loading')}
          </Alert>
        );
      case ProgressState.Empty:
        return <p style={validatingStyle}>{t(isTeacher && selectedStatusFilter === ItemStatus.Submitted ? 'workbook.all-done' : 'list-empty')}</p>;
    }
  }

  return (
    <>
      <CourseStudentSelectorSidebar
        selectionMode={isTeacher ? 'single-and-all-students' : 'course-only'}
        footer={
          isTeacher &&
          serverInfo?.enrolcode_enabled && (
            <GtnButton label={t('invite-students.title')} actionType="primary" className={styles.inviteStudentsButton} onClick={() => inviteStudentsDialog.open({ courseId: selectedCourse?.id })} />
          )
        }
      >
        {renderFilters}
        {renderContent()}
        {isValidating && progressState != ProgressState.Loading && <LoadingIndicatorOverlay />}
      </CourseStudentSelectorSidebar>

      {!isTeacher && selectedTypeFilter !== TypeFilter.Examples && (
        <Fab className={styles.newButton} variant="extended" color="secondary" onClick={onNewEntryClicked}>
          <Add className={styles.newButtonIcon} />
          <span className={styles.newButtonText}>{t('workbook.new-entry')}</span>
        </Fab>
      )}
      <chooseCompetenceFilterDialog.Component
        onSubjectClicked={({ subject }) => {
          setSelectedSubjectFilter(subject);
          setSelectedTopicFilter(null);
          setSelectedNiveauFilter(null);

          chooseCompetenceFilterDialog.close();
        }}
        onTopicClicked={({ subject, topic }) => {
          setSelectedSubjectFilter(subject);
          setSelectedTopicFilter(topic);
          setSelectedNiveauFilter(null);

          chooseCompetenceFilterDialog.close();
        }}
      />
      <chooseCompetenceForAddItemDialog.Component
        onTopicClicked={({ subject, topic }) => {
          const exampleAndItem: ExampleAndItemResponse = {
            courseid: subject.courseid,
            subjectid: subject.id,
            subjecttitle: subject.title,
            topicid: topic.id,
            topictitle: topic.title,
            status: ItemStatus.Inprogress,
          };

          chooseCompetenceForAddItemDialog.close();
          // hack, da sofort öffnen nicht geht
          // und bei backbutton sollen sich beide dialoge schliessen
          setTimeout(() => exampleItemDialog.open({ exampleAndItem }), 200);
        }}
      />
      <exampleItemDialog.Component onSave={reloadItems} />
      <inviteStudentsDialog.Component />
    </>
  );
}
